import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo />
      <div className="px-2 md:px-0">
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug

          return (
            <article
              itemScope
              itemType="http://schema.org/Article"
              className="mx-2 py-6 border-t first:border-none"
              key={post.fields.slug}
            >
              <Link
                className="flex items-center"
                to={`/posts${post.fields.slug}`}
                itemProp="url"
              >
                <div className="mr-4">
                  <time
                    datetime={post.frontmatter.date}
                    className="text-base uppercase min-w-24 whitespace-nowrap mt-0 font-bold text-primary"
                  >
                    {post.frontmatter.shortDate}
                  </time>
                </div>
                <h2 className="text-xl mb-0 mt-1 whitespace-nowrap flex-grow text-ellipsis overflow-hidden">
                  <span itemProp="headline">{title}</span>
                </h2>
                <div className="text-gray-600 text-base min-w-32 ml-auto text-right whitespace-nowrap">
                  {Math.floor(post.fields.readingTime.minutes)} min read
                </div>
              </Link>
            </article>
          )
        })}
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { draft: { eq: false } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
          readingTime {
            minutes
          }
        }
        frontmatter {
          date(formatString: "YYYY-MM-DD")
          shortDate: date(formatString: "MMM DD")
          title
          description
        }
      }
    }
  }
`
